/* eslint-disable no-useless-escape */
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { theme } from '../../theme';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
	},
	content: {
		width: "100%",
		padding: 30,
		marginTop: 20,
		background: theme.palette.primary.main,
		color: "white",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
	},
	button: {
		background: "white",
		color: theme.palette.primary.main,
	}
}));

export function Contact(props) {
		const classes = useStyles();

		return (
			<Box className={classes.root}>
				<Box id={props.id} boxShadow={3} className={classes.content}>
					<Grid container spacing={theme.spacing(4)} alignItems="center">
						<Grid item xs={12}>
							<Typography variant="h4" align="center">
								Hast du Interesse?
							</Typography>
							<Typography variant="h6" align="center">
								Dann registriere dich jetzt und du bekommst eine Kundenkarte von uns.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Box className={classes.buttonContainer}>
								<Button
									id="registrationButton"
									className={classes.button}
									variant="contained"
									size="large"
									href="/register">
									Jetzt Registrieren
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		);
}
