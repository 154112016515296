import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { formatDateTime } from "../utilities/dateUtils";

function DeliveryDateInput({
	deliveryDate,
	locker,
	deliveryDates,
	onRemainingMinutesChange,
	onChange,
}) {
	const [remainingMinutes, setRemainingMinutes] = useState(0);

	useEffect(() => {
		setRemainingMinutes(calcRemainingMinutes(deliveryDate));
		const timer = setInterval(() => {
			setRemainingMinutes(calcRemainingMinutes(deliveryDate));
		}, 60000);
		return () => clearInterval(timer);
	}, [deliveryDate]);

	useEffect(() => {
		onRemainingMinutesChange(remainingMinutes);
	}, [remainingMinutes]);

	const calcRemainingMinutes = (deliveryDate) => {
		let remainingMinutes = 0;
		if (deliveryDate && deliveryDate !== null) {
			const now = new Date().getTime();
			const deadline = new Date(deliveryDate.deadlineDate).getTime();
			remainingMinutes = Math.floor((deadline - now) / 60000);
		}
		return remainingMinutes;
	};

	const getDeliveryDateDisplayText = (deliveryDate) => {
		let displayText = formatDateTime(deliveryDate.deliveryDate);
		if (locker === null) {
			displayText += " (" + deliveryDate.locker.title + ")";
		}
		return displayText;
	};

	const getRemainingTimeText = () => {
		let text = "";
		if (deliveryDate && deliveryDate !== null) {
			if (remainingMinutes > 0) {
				const minutes = remainingMinutes % 60;
				const hours = Math.floor(remainingMinutes / 60);
				text = "Noch " + hours + " Stunden und " + minutes + " Minuten";
			} else {
				text = "Bestellschluss abgelaufen";
			}
		}
		return text;
	};

	return (
		<Box>
			<Select
				value={
					deliveryDate && deliveryDate !== null && deliveryDate.id
						? deliveryDate.id.toString()
						: ""
				}
				style={{ display: "block", textAlign: "center" }}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			>
				<MenuItem key={"deliveryDate0"} value={""} component={Box}>
					Keine Lieferzeit ausgewählt
				</MenuItem>
				{deliveryDates
					.filter(
						(deliveryDate) =>
							locker === null || deliveryDate.locker.id === locker.id
					)
					.sort(
						(date1, date2) =>
							new Date(date1.deliveryDate).getTime() -
							new Date(date2.deliveryDate).getTime()
					)
					.map((deliveryDate) => (
						<MenuItem
							key={"deliveryDate" + deliveryDate.id}
							value={deliveryDate.id}
							component={Box}
						>
							{getDeliveryDateDisplayText(deliveryDate)}
						</MenuItem>
					))}
			</Select>
			<Typography
				variant="caption"
				color={remainingMinutes > 0 ? "initial" : "error"}
				component="p"
				align="center"
				gutterBottom
			>
				{getRemainingTimeText()}
			</Typography>
			{deliveryDate != null ? (
				deliveryDate.freeCompartments > 0 ? (
					<Typography align="center" color="secondary">
						Aktuell sind noch Fächer frei
					</Typography>
				) : (
					<Typography align="center" color="error">
						Leider sind bereits alle Fächer belegt
					</Typography>
				)
			) : null}
		</Box>
	);
}

export default DeliveryDateInput;
