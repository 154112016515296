/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import {
	Backdrop,
	Box,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	makeStyles,
	Snackbar,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import { useAuth } from "../../provider/authProvider";
import { useHeader } from "../../provider/headerProvider";
import { theme } from "../../theme";
import Divider from "../utilities/divider";
import Logger from "../utilities/logger";
import AccountManagementButton from "./accountManagementButton";
import { getProduct } from "./deliveryAction";
import DeliveryCartCheckoutList from "./deliveryCartCheckoutList";
import DeliveryCartList from "./deliveryCartList";

const logger = new Logger({ level: LOG_LEVEL.INFO, target: "cart" });

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#eee",
		flexGrow: 2,
	},
	alert: {
		paddingBlock: 15,
		paddingInline: 25,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

function DeliveryCart() {
	const minimumOrderValue = 10.0;
	const auth = useAuth();
	const header = useHeader();
	const mobile = useMediaQuery("(max-width:600px)");
	const [loading, setLoading] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [feedback, setFeedback] = useState(null);
	const { state, dispatch } = useContext(AppContext);

	const classes = useStyles();

	const syncCart = () => {
		let error = true;
		auth
			.request(
				window._env_.RS_HOST.concat("/api/v2/regiostore/delivery/cart/sync"),
				{
					method: "POST",
					body: JSON.stringify(state.cart),
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				if (response.ok) {
					error = false;
					return response.json();
				}
			})
			.then((data) => {
				if (!error) {
					dispatch({
						type: "setCart",
						cart: data,
						overwrite: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getCart = () => {
		if (!loading) {
			let error = true;
			setLoading(true);
			auth
				.request(
					window._env_.RS_HOST.concat(
						"/api/v2/regiostore/delivery/cart?deviceId=" + state.deviceId
					),
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((response) => {
					if (response.ok && response.status === 200) {
						error = false;
						return response.json();
					}
				})
				.then((data) => {
					if (!error) {
						dispatch({
							type: "setCart",
							cart: data,
							overwrite: true,
						});
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (header.icons && header.icons.length <= 0) {
			header.setIcons([<AccountManagementButton />]);
		}
	}, [header]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (auth.isInitialized() && auth.loggedIn()) {
				if (state.cart && state.cart.changed) {
					syncCart();
				}
			}
		}, 3000);
		return () => clearTimeout(timer);
	}, [auth, state]);

	useEffect(() => {
		if (auth.isInitialized() && auth.loggedIn()) {
			if (initialLoad && !(state.cart && state.cart.changed)) {
				getCart();
				setInitialLoad(false);
			}
		}
	}, [auth, initialLoad]);

	const checkout = () => {
		if (auth.isInitialized() && auth.loggedIn()) {
			let error = true;
			setLoading(true);
			auth
				.request(window._env_.RS_HOST.concat("/api/v2/regiostore/delivery"), {
					method: "POST",
					body: JSON.stringify(state.cart),
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					if (response.ok) {
						error = false;
					}
					return response.text();
				})
				.then((data) => {
					if (!error) {
						dispatch({
							type: "emptyCart",
						});
					} else {
						console.log(data);
					}
					setFeedback({ error: error, text: data });
				})
				.catch((error) => {
					setFeedback({ error: true, text: error });
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const changeCartQuantity = (index, product, quantity) => {
		let item = state.cart.items[index];
		dispatch({
			type: "addToCart",
			product: product ? product : getProduct(state, item.product.id),
			barcode: item.codedBarcode,
			quantity: quantity,
		});
	};

	const changeCouponCode = (coupon) => {
		dispatch({
			type: "changeCartCouponCode",
			coupon: coupon,
		});
	};

	const changeDeliveryDate = (deliveryDate) => {
		dispatch({
			type: "changeCartDeliveryDate",
			deliveryDate: deliveryDate,
		});
	};

	return (
		<Box p={4} className={classes.root}>
			<Grid
				container
				spacing={theme.spacing(4)}
				direction={mobile ? "column-reverse" : "row"}
			>
				<Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
					<Card>
						<CardContent>
							<Typography variant="h4" color="textPrimary" gutterBottom>
								Dein Warenkorb
							</Typography>
							<Divider align="left" />
						</CardContent>
						<DeliveryCartList onChangeQuantity={changeCartQuantity} />
					</Card>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
					<Card>
						<CardContent>
							<Typography variant="h4" color="textPrimary" gutterBottom>
								Abschließen
							</Typography>
							<Divider align="left" />
						</CardContent>
						<DeliveryCartCheckoutList
							minimumOrderValue={minimumOrderValue}
							onCouponChange={changeCouponCode}
							onDeliveryDateChange={changeDeliveryDate}
							onCheckout={checkout}
						/>
					</Card>
				</Grid>
			</Grid>
			<Snackbar
				id="feedbackSnackbar"
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={feedback !== null}
				onClose={() => setFeedback(null)}
			>
				<Alert
					id="feedbackAlert"
					variant="filled"
					className={classes.alert}
					onClose={() => setFeedback(null)}
					severity={feedback && feedback.error ? "error" : "success"}
				>
					{feedback && feedback.error
						? "Es gab einen Fehler beim Abschließen: "
						: null}
					{feedback ? feedback.text : null}
				</Alert>
			</Snackbar>
			<Backdrop open={loading} className={classes.backdrop}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
}

export default DeliveryCart;
