import { Box, makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import React, { useState } from "react";
import BarcodeReader from 'react-barcode-reader';
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
    alert: {
        paddingBlock: 15,
        paddingInline: 25,
    },
}));

function CardScanner({scanDisabled, onCardScan, onCardScanAfter}) {
    const classes = useStyles();
    const [errorText, setErrorText] = useState("");
    const [warningSound] = useState(new Audio("sounds/warning.wav"));

    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "cardscanner" });

    const handleScan = (rfid) => {
        logger.debug("handle scan");
        if (!scanDisabled) {
            onCardScan();
            let error = false;
            var targetUrl = window._env_.RS_HOST.concat("/api/v1/regiostore/customer?rfid=" + rfid);
            fetch(encodeURI(targetUrl), {
                method: 'GET',
            }).then(response => {
                if(!response.ok) {
                    error = true;
                }
                return response.text();
            }).then(text => {
                if (error) {
                    logger.error("Error in checking customer request (/api/v1/regiostore/customer):", text);
                    setErrorText(text);
                    onCardScanAfter(null, null);
                } else {
                    onCardScanAfter(rfid, JSON.parse(text));
                }
            }).catch(function(error) {
                logger.error("Error in checking customer request (/api/v1/regiostore/customer):", error);
                setErrorText(error.toString());
                onCardScanAfter(null, null);
            });
        }
    }

    const handleError = (err) => {
        logger.debug("handle error");
        logger.error("error on card scan:",err);
        if (err !== null && err.trim() !== "") {
            setErrorText("Es gab einen Fehler beim Scannen der Karte:" + err);
        }
        warningSound.play();
    }

    const handleScanErrorClose = () => {
        logger.debug("handle scan");
        setErrorText("");
    }

    return (
        <Box>
            <BarcodeReader
                onError={handleError}
                onScan={handleScan}
            />
            <Snackbar
                id="cardScanErrorSnackbar"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorText !== ""} 
                autoHideDuration={6000} 
                onClose={handleScanErrorClose}
            >
                <Alert 
                    id="cardScanErrorAlert"
                    variant="filled"
                    className={classes.alert}
                    onClose={handleScanErrorClose} 
                    severity="error"
                >
                    {errorText}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default CardScanner;