/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import { FILTER } from "../../enums/product";
import { useAuth } from "../../provider/authProvider";
import { useHeader } from "../../provider/headerProvider";
import { useStorage } from "../../useStorage";
import { calcTotalAmount } from "../products/productUtils";
import Logger from "../utilities/logger";
import { formatNumber } from "../utilities/numberUtils";
import AccountManagementButton from "./accountManagementButton";
import DeliveryDateDialog from "./deliveryDateDialog";
import DeliveryList from "./deliveryList";
import ShoppingCartButton from "./shoppingCartButton";

const logger = new Logger({ level: LOG_LEVEL.INFO, target: "delivery" });

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#eee",
		flexGrow: 2,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

function Delivery(props) {
	const auth = useAuth();
	const header = useHeader();
	const { state, dispatch } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [deliveryDates, setDeliveryDates] = useState([]);
	const [showDeliveryDateDialog, setShowDeliveryDateDialog] = useStorage(
		window.sessionStorage,
		"showDeliveryDateDialog",
		true
	);
	const [shoppingCartButtonClass, setShoppingCartButtonClass] = useState(null);

	const classes = useStyles();

	useEffect(() => {
		if (showDeliveryDateDialog && !cartHasDeliveryDate()) {
			let error = true;
			fetch(window._env_.RS_HOST.concat("/api/v1/locker/deliverydate"), {
				method: "GET",
			})
				.then((response) => {
					if (response.ok) {
						error = false;
						return response.json();
					}
				})
				.then((data) => {
					if (!error) {
						setDeliveryDates(data);
					} else {
						console.log("Error getting delivery dates: " + data);
					}
				})
				.catch((error) => {
					console.log("Error getting delivery dates: " + error);
				});
		}
	}, [state]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (auth.isInitialized() && auth.loggedIn()) {
				if (state.cart && state.cart.changed) {
					syncCart();
				}
			}
		}, 3000);
		return () => clearTimeout(timer);
	}, [auth, state]);

	useEffect(() => {
		let icons = [];
		const totalAmount = calcTotalAmount(
			state.cart.items,
			state.cart.discount,
			state.cart.coupon
		);
		if (totalAmount > 0) {
			icons.push(
				<Typography variant="h6" color="textPrimary">
					{formatNumber(totalAmount, 2)} €
				</Typography>
			);
		}
		header.setIcons([
			...icons,
			<ShoppingCartButton state={state} className={shoppingCartButtonClass} />,
			<AccountManagementButton />,
		]);
	}, [state, shoppingCartButtonClass]);

	useEffect(() => {
		setLoading(true);
		var targetUrl = window._env_.RS_HOST.concat(
			"/api/v1/product/all?filter=" + FILTER.CATEGORY
		);
		fetch(encodeURI(targetUrl), {
			method: "GET",
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					logger.warn(
						"Response not ok in product request (/api/v1/product/all):",
						response
					);
				}
			})
			.then((data) => {
				setLoading(false);
				if (data) {
					dispatch({
						type: "setProducts",
						products: data,
					});
				} else {
					logger.warn("No data in product request (/api/v1/product/all)");
				}
			})
			.catch(function (error) {
				logger.error("Error in product request (/api/v1/product/all):", error);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		let timer = null;
		if (shoppingCartButtonClass !== null) {
			timer = setTimeout(() => {
				setShoppingCartButtonClass(null);
			}, 3000);
		}
		return () => clearTimeout(timer);
	}, [shoppingCartButtonClass]);

	const syncCart = () => {
		let error = true;
		auth
			.request(
				window._env_.RS_HOST.concat("/api/v2/regiostore/delivery/cart/sync"),
				{
					method: "POST",
					body: JSON.stringify(state.cart),
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				if (response.ok) {
					error = false;
					return response.json();
				}
			})
			.then((data) => {
				if (!error && data && data !== null) {
					dispatch({
						type: "setCart",
						cart: data,
						overwrite: false,
					});
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const changeCartQuantity = (product, quantity) => {
		setShoppingCartButtonClass("shake");
		dispatch({
			type: "addToCart",
			product: product,
			quantity: quantity,
		});
	};

	const cartHasDeliveryDate = () => {
		return state && state.cart && state.cart.deliveryDate;
	};

	return (
		<Box p={4} className={classes.root}>
			<DeliveryList
				products={state.products}
				filter={FILTER.CATEGORY}
				showStock={true}
				searchBar={true}
				onListItemClick={() => {}}
				onListItemQuantityChange={changeCartQuantity}
				offset={props.headerHeight}
				fullscreen={false}
				infiniteScroll={true}
				showBuyButton={true}
			/>
			<DeliveryDateDialog
				open={
					showDeliveryDateDialog &&
					deliveryDates &&
					deliveryDates.length > 0 &&
					!cartHasDeliveryDate()
				}
				deliveryDates={deliveryDates}
				onClose={() => setShowDeliveryDateDialog(false)}
			/>
			<Backdrop open={loading} className={classes.backdrop}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<HashLink to={{ pathname: "/cart" }} className={classes.shoppingCartIcon}>
				<Button
					size="large"
					color="secondary"
					variant="contained"
					fill="solid"
					style={{
						position: "fixed",
						bottom: 30,
						left: "50%",
						margin: 0,
						MsTransform: "translateX(-50%)",
						transform: "translateX(-50%)",
					}}
				>
					Zum Warenkorb
				</Button>
			</HashLink>
		</Box>
	);
}

export default Delivery;
