/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, ButtonGroup, Card, CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { useContext, useEffect, useState } from "react";
import {
    useLocation
} from "react-router-dom";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import ProductList from "../products/productList";
import Logger from "../utilities/logger";
import Countdown from './countdown';
import { checkShoppingCartState } from "./reservationAction";
import ShoppingCart from './ShoppingCart';

const logger = new Logger({ level: LOG_LEVEL.INFO, target: "reservation" });
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#eee",
        paddingBottom: 300
    },
    countdownCard: {
        background: "linear-gradient(25deg, #3f3c59, #3f3c59, #2E8099)",
        //3f3c59  57B793
    },
    countdownCardContent: {
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    counter: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
        margin: "10px auto",
        padding: "5px",
    },
    time: {
        margin: "0 3vw 0",
    },
    timeValue: {
        padding: "1px",
        fontSize: "clamp(3rem, 10vw ,1.3rem)",
        color: "#fff",
        lineHeight: 1,
    },
    timeLabel: {
        textAlign: "center",
        color: "#57B793",
        fontSize: "clamp(0.5rem, 3vw ,1.3rem)",
    },
    correctShoppingCartModal: {
        background: "linear-gradient(25deg, #3f3c59, #3f3c59, #2E8099)",
        color: "#fff"
    }
}));

function Reservation(props) {
    // TODO: Loading Backdrop
    const mobile = useMediaQuery('(max-width:600px)')
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AppContext);
    const [shoppingCartModalVisible, setShoppingCartModalVisible] = useState(false);
    const [startDate, setStartDate] = useState(new Date('2022-11-22T18:00:00'));
    const [counter, setCounter] = useState(Math.round(((startDate.getTime() - new Date().getTime()) / 1000)));

    //const { testvalue } = useParams();
    const { query, search } = useLocation(); 
    const classes = useStyles();

    useEffect(() => {
        refreshProducts();
        if(search.includes("openCart")){
            setShoppingCartModalVisible(true);
        }
        refreshTimeCounter();
    },[])

    const refreshTimeCounter = () => {
        setTimeout(function(){
            if(counter > 0){
                var newCounterState = Math.floor((startDate.getTime() - new Date().getTime()) / 1000); 
                setCounter(newCounterState);             
            }
            refreshTimeCounter();
        }, 1000);
    }

    const refreshProducts = () => {
        setLoading(true);
        var targetUrl = window._env_.RS_HOST.concat("/api/v1/regiostore/reservation/all");
        
        fetch(encodeURI(targetUrl), {
            method: 'GET',
        }).then(response => {
            if(response.ok) {
                return response.json();
            } else {
                logger.warn(state.deviceId.concat(" Response not ok in product request (/api/v1/regiostore/reservation/all):"), response);
            }
        }).then(data => {
            setLoading(false);
            data.forEach(j => j.webProduct.product.stock = j.quantity);
            if(data) {
                dispatch({
                    type: 'setReservationProducts',
                    reservationProducts: data
                });
                var removedProducts = checkShoppingCartState(state.shoppingCartProducts, data);
                if(removedProducts.length > 0){
                    dispatch({
                        type: 'setWrongShoppingCart',
                        wrongShoppingCart: true,
                        removedProducts: removedProducts,
                    });
                    dispatch({
                        type: 'correctShoppingCart', 
                    });
                }
            } else {
                logger.warn(state.deviceId.concat(" No data in product request (/api/v1/regiostore/reservation/all)"))
            }
            logger.info(state.deviceId.concat(" product request (/api/v1/regiostore/reservation/all) successful"));
            return data;
        }).catch(function(error) {
            logger.error(state.deviceId.concat(" Error in product request (/api/v1/regiostore/reservation/all):", error));
            setLoading(false);
        }).finally(() => {
            if(counter > 0){
                setTimeout(function(){
                    refreshProducts();
                }, 60000);
            }else{
                setTimeout(function(){
                    refreshProducts();
                }, 10000);
            }
        });
    }

    function userCanShop(){
        return counter < 0 || state.cheat === "KatharinaDieGroße";
    }

    function closeCorrectShoppingCartModal(){
        dispatch({
            type: 'setWrongShoppingCart', 
            wrongShoppingCart: false
        });
        dispatch({
            type: 'correctShoppingCart', 
        });
    }

    const sortProducts = (a , b) => {
        if(a.title.toLowerCase().includes("rind") && !b.title.toLowerCase().includes("rind")){
            return -1;
        }
        if(!a.title.toLowerCase().includes("rind") && b.title.toLowerCase().includes("rind")){
            return 1;
        }
        else{
            return a.title.localeCompare(b.title);
        }
    }

    // TODO: Extra component for reservation modal
    function renderCorrectShoppingCartModal(){
        return(           
          <Dialog  fullWidth={true} fullScreen={window.innerWidth < 800} scroll="body" maxWidth="sm" onClose={() => closeCorrectShoppingCartModal()} aria-labelledby="customized-dialog-title" open={state.wrongShoppingCart}>
              <Toolbar className={classes.correctShoppingCartModal} >
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Typography variant="h6">
                        Leider nicht mehr alles verfügbar
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton style={{margin: 0, padding:0}} edge="end" color="inherit" onClick={() => closeCorrectShoppingCartModal()} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              
              <DialogContent  dividers style={{position: "relative"}}>
                <Typography>
                        Leider ist nicht mehr alles verfügbar, was du in deinem Warenkorb hattest. Folgendes mussten wir entfernen:
                </Typography>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Produkt</TableCell>
                            <TableCell align="right">Anzahl</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {state.removedProductsFromShoppingCart.length > 0 && state.removedProductsFromShoppingCart.map((removedProduct) => 
                            <TableRow
                                key={removedProduct.webProductTitle}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {removedProduct.webProductTitle}
                                </TableCell>
                                <TableCell align="right">{removedProduct.count}</TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        </Table>
                    </TableContainer>
                <ButtonGroup style={{width:"100%", marginTop:20}}>
                    <Button onClick={() => closeCorrectShoppingCartModal()} color="primary" size="large" style={{width:"100%"}} variant="contained">
                        Okay
                    </Button>
                </ButtonGroup>
              </DialogContent>
          </Dialog>              
        )
    }

    return <Box p={4} className={classes.root}>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={userCanShop() ? 6 : false} lg={userCanShop() ? 8 : false} xl={userCanShop() ? 8 : false}>
                {counter > 0 ? 
                <Card style={{ margin: "auto auto 1rem auto" }} className={classes.countdownCard}>
                    <CardContent sx={{ flex: '1 0 auto' }} className={classes.countdownCardContent}>   
                        <Countdown startDate={startDate} counter={counter}/>
                    </CardContent>                            
                </Card>: <></>}
                <Card style={{ margin: "auto auto 1rem auto" }}>
                    <Grid container>
                        <Grid item >
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography style={{fontSize: "clamp(17px, 1.7vw, 30px)", color: "#3f3c59"}} component="div" variant="h6">
                                    Weihnachtsreservierung (22. & 23.12.) 🎄
                                </Typography>
                                <Typography style={{fontSize: "clamp(12px, 1.5vw, 20px)", color: "#3f3c59"}} variant="subtitle1" component="div">
                                    Zum 22. & 23. Dezember kannst Du alles für Dein Festessen zu Weihnachten vorbestellen: regionales Rind & Schwein aus Bissendorf, Lamm, Wild, Geflügel, Fisch, Veganes, Beilagen, Käse und Dessert. Es sollte also für jede*n etwas dabei sein. 😊
                                </Typography>
                                <Typography style={{fontSize: "clamp(12px, 1.5vw, 20px)", color: "#3f3c59"}} variant="subtitle1" component="div">
                                    Du kannst die Produkte vorbestellen, solange der Vorrat reicht, spätestens allerdings bis zum 18.12.2022. Bitte beachte, dass es für einige Produkte gesonderte Bestellfristen gibt. ⏲️</Typography>
                            </CardContent>                            
                        </Grid>
                    </Grid>
                </Card>
                <ProductList
                    products={state.reservationProducts.map( w => w.webProduct).sort((a, b) => sortProducts(a,b))}
                    filter={false}
                    // filterAnimalBreed={{"Rind": ["Rind","Galloway"], "Wild": ["Wild"], "Lamm": ["Lamm"], "Schwein": ["Schwein"], "Fisch": ["Fisch"], "Geflügel": ["Ente", "Pute", "Hähnchen", "Poularde"],"Beilagen": ["Kloßteig", "Rotkohl", "Spätzle"], "Käse": ["Gouda"], "Vegan": ["vegan"], "Dessert": ["Dessert"]}}
                    filterAnimalBreed={{"Rind": ["Rind","Galloway","Raclette-Fleisch"], "Wild": ["Damwild", "Wildfond","Hirsch"], "Lamm": ["Lamm-Rosmarin-Braten", "Lammgulasch", "Lammkeule", "Lamm-Lachsbraten", "Lammhack", "Lammsteak", "Lammfond"], "Schwein": ["Schwein","Raclette-Fleisch","Grieben-Schmalz","Apfel-Schmalz"], "Fisch": ["Forelle","Saibling","Scholle","Garnele", "Atlantik-Lachs", "Irischer Lachs", "Lachshäppchen", "Stremel Lachs", "Dill Lachs", "Wildlachs"], "Geflügel": ["Ente", "Pute", "Hähnchen", "Poularde","Gans","Gänse","Raclette-Fleisch","Huhn","Hühner"],"Beilagen": ["Kloßteig", "Rotkohl", "Spätzle","Maultasche","Schupfnudel","Meerettich","Kartoffel", "Erbsen", "Kräuterbutter", "Blätterteig", "Krokette", "Knödel"], "Käse": ["Gouda","käse","ribeau","charmeux","alpen","comt","emmentaler","almkönig","bernd","rusticus","allgäu","cheddar","manchego","gruy","appenzeller","pecorino","kolumbus","leonardo","gold", "Brie", "Jil", "Schimmel"], "Vegan": ["Seitan","vegan","quinoa","tofu","lupine","gemüsefond"], "Dessert": ["Dessert","tiramisu","cotta","eisstern","choc","vanille","schoko", "blätterteig"]}}
                    // filterAnimalBreed={[]}
                    showStock={userCanShop()}
                    searchBar={true}
                    onListItemClick={() => {}}
                    offset={props.headerHeight}
                    fullscreen={false}
                    infiniteScroll={false}
                    showBuyButton={userCanShop()}
                    showShoppingCart={userCanShop()}
                    showRegionalLabels={true}
                    isFlipCard={false}
                    sortSoldOut={true}
                    showInfoButton={true}
                />
            </Grid>
            { !mobile && userCanShop() ?
                //BrowserView 
                <Grid item xs={false} sm={false} md={6} lg={4} xl={4}>
                    <ShoppingCart/>
                </Grid> 
                : <></>}
            { mobile && userCanShop() ?    
                //Mobile-View
                <Dialog fullScreen={true} scroll="body" onClose={() => setShoppingCartModalVisible(false)} aria-labelledby="customized-dialog-title" open={shoppingCartModalVisible}>
                    <IconButton style={{position: "absolute", right: 10, top: 5, color: "#efefef", zIndex: 999}} edge="end" color="inherit" onClick={() => setShoppingCartModalVisible(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent style={{padding: 0,  backgroundColor: "#3f3c59", color:"#ff0000", height:"100%"}}>
                        <ShoppingCart/>
                    </DialogContent>
                </Dialog>:<></>
            }
            

            
        </Grid>
        {mobile && userCanShop() ? <footer onClick={() => {setShoppingCartModalVisible(true)}} className={classes.root} style={{ paddingBlock: 20, margin: "auto",
                width: "100%",
                paddingInline: "1rem",
                display: "flex",
                fontSize: "large",
                overflow: "hidden",
                flexShrink: 0,
                background: "#3f3c59",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)",
                position: "fixed",
                zIndex: 999,
                bottom: 0,
                left: 0, }}>
                <div style={{ paddingLeft: 20, paddingRight: 20, width: "80%"}}>
                    <Button style={{fontSize: "large", width:"100%", backgroundColor: "#fc9800", color: "#efefef"}} size="large" variant="contained">Zum Warenkorb</Button>
                </div>
            </footer>:<></>}
            {renderCorrectShoppingCartModal()}
    </Box>
}

export default Reservation;