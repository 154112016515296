import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  spacing: 2,
  typography: {
    fontFamily: 'Ubuntu',
  },
  palette: {
    primary: {
      main: '#fc9800',
      contrastText: "#f2f2f2",
    }, 
    secondary:{
      main:'#57B793',
      contrastText: "#f2f2f2",
    },
    tertiary: {
      main:'#3f3c59',
      contrastText: "#f2f2f2",
    },
    text: {
      primary: "#3f3c59",
      //secondary: "#333",
    },
    background: {
      main: "#ffffff",
    },
    error: {
      main: "#f44336",
      contrastText: "#f2f2f2",
    },
  }
});