import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Heading } from "../utilities/heading";
import Teammember from "./teammember";

const useStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    content: {
        width: "100%",
        maxWidth: 1152,
        padding: 30,
        marginTop: 60,
    },
});

export function Team(props) {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box id={props.id} border={0} className={classes.content}>
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid key="team-heading" item xs={12}>
                        <Heading title="Das Team" />
                    </Grid>
                    {props.team && !!props.team.length && props.team.map((teammember) => (
                        <Grid key={teammember.name} item xs>
                            <Teammember name={teammember.name} avatarPath={teammember.avatarPath} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}