import {
	Box,
	Button,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import ProductDetailDialog from "../products/productDetailDialog";
import CheckoutItem from "../terminal/checkoutItem";
import { getProduct, isCodedBarcode } from "./deliveryAction";
import FeedbackPopover from "./feedbackPopover";
import RemovedItemsDialog from "./removedItemsDialog";

const useStyles = makeStyles((theme) => ({
	root: {},
	emptyCart: {
		padding: 16,
		display: "flex",
		flexDirection: "row-reverse",
	},
	emptyCartButton: {
		background: theme.palette.error.main,
		color: theme.palette.error.contrastText,
	},
}));

function DeliveryCartList({ onChangeQuantity }) {
	const classes = useStyles();
	const { state, dispatch } = useContext(AppContext);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [removedItems, setRemovedItems] = useState([]);
	const [feedback, setFeedback] = useState(null);
	const [feedbackPopoverAnchor, setFeedbackPopoverAnchor] = useState(null);

	useEffect(() => {
		if (
			state &&
			state.cart &&
			state.cart.items &&
			state.cart.items.length > 0
		) {
			let removedItems = [];
			state.cart.items.forEach((item) => {
				const product = getProduct(state, item.product.id);
				if (product) {
					if (product.stock < item.quantity) {
						let correctItem = structuredClone(item);
						correctItem.quantity = correctItem.quantity - product.stock;
						removedItems.push(correctItem);
					}
				} else {
					removedItems.push(item);
				}
			});
			if (removedItems && removedItems.length > 0) {
				setRemovedItems(removedItems);
				dispatch({
					type: "correctCart",
					itemsToRemove: removedItems,
				});
			}
		}
	}, [state]);

	const emptyCart = () => {
		dispatch({
			type: "emptyCart",
		});
	};

	const changeQuantity = (e, index, target, quantity) => {
		e.stopPropagation();
		const product = getProduct(state, state.cart.items[index].product.id);
		if (product) {
			if (product.stock < quantity) {
				setFeedback("Du hast die maximal verfügbare Menge erreicht.");
				setFeedbackPopoverAnchor(target);
			} else {
				onChangeQuantity(index, product, quantity);
			}
		}
	};

	const onCartItemClick = (cartItem) => {
		const product = getProduct(state, cartItem.product.id);
		if (product !== null) {
			setSelectedProduct(product);
		}
	};

	const getCartItem = (cartItem, index) => {
		const product = getProduct(state, cartItem.product.id);
		let quantitySteps = 1;
		if (product && product.priceUnit === "WEIGHT" && !isCodedBarcode(product)) {
			quantitySteps = product.quantitySteps;
		}
		return (
			<Grid key={"cartItem" + index} item xs={12}>
				<CheckoutItem
					color="default"
					image={product && product.imagePath}
					index={index}
					item={cartItem}
					quantitySteps={quantitySteps}
					onChangeQuantity={changeQuantity}
					onClick={onCartItemClick}
				/>
				<Divider />
			</Grid>
		);
	};

	return (
		<Box className={classes.root}>
			<Grid container>
				{state.cart.items.map((cartItem, index) =>
					getCartItem(cartItem, index)
				)}
				{state.cart.items.length <= 0 ? (
					<Grid key="NoCartItem" item xs={12}>
						<Typography variant="body1" align="center" gutterBottom>
							Dein Warenkorb ist leer.
						</Typography>
					</Grid>
				) : (
					<Grid key="emptyCart" item xs={12}>
						<Box className={classes.emptyCart}>
							<Button
								className={classes.emptyCartButton}
								variant="contained"
								startIcon={<DeleteIcon />}
								onClick={emptyCart}
							>
								Warenkorb leeren
							</Button>
						</Box>
						<ProductDetailDialog
							open={selectedProduct !== null}
							product={selectedProduct}
							onClose={() => setSelectedProduct(null)}
							showRegionalLabels={true}
						/>
						<RemovedItemsDialog
							open={removedItems && removedItems.length > 0}
							items={removedItems}
							handleClose={() => setRemovedItems([])}
						/>
						<FeedbackPopover
							anchor={feedbackPopoverAnchor}
							feedback={feedback}
							onClose={() => setFeedbackPopoverAnchor(null)}
						/>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}

export default DeliveryCartList;
