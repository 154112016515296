import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CardGiftcard } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import { useAuth } from "../../provider/authProvider";
import { calcAmount } from "../products/productUtils";
import CheckoutAmount from "../terminal/checkoutAmount";
import Logger from "../utilities/logger";
import { formatNumber, round } from "../utilities/numberUtils";
import SimpleDialog from "../utilities/simpleDialog";
import DeliveryCartCheckoutItem from "./deliveryCartCheckoutItem";
import DeliveryCouponDialog from "./deliveryCouponDialog";
import DeliveryDateInput from "./deliveryDateInput";
import DeliveryLockerInput from "./deliveryLockerInput";

const logger = new Logger({ level: LOG_LEVEL.INFO, target: "state.cart" });

const useStyles = makeStyles((theme) => ({
  root: {},
  checkoutButton: {
    paddingBlock: 8,
    paddingInline: 16,
  },
  continueShoppingButton: {
    marginBlockStart: 6,
  },
}));

function DeliveryCartCheckoutList({
  minimumOrderValue,
  onCouponChange,
  onDeliveryDateChange,
  onCheckout,
}) {
  const auth = useAuth();
  const { state } = useContext(AppContext);
  const [amount, setAmount] = useState(0);
  const [lockers, setLockers] = useState([]);
  const [locker, setLocker] = useState(null);
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [openCouponDialog, setOpenCouponDialog] = useState(false);
  const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    let error = true;
    fetch(window._env_.RS_HOST.concat("/api/v1/locker/deliverydate"), {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          error = false;
          return response.json();
        }
      })
      .then((data) => {
        if (!error) {
          setDeliveryDates(data);
        } else {
          console.log("Error getting delivery dates: " + data);
        }
      })
      .catch((error) => {
        console.log("Error getting delivery dates: " + error);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (auth.isInitialized() && auth.loggedIn()) {
        let error = true;
        auth
          .request(window._env_.RS_HOST.concat("/api/v2/regiostore/customer"), {
            method: "GET",
          })
          .then((response) => {
            if (response.ok) {
              error = false;
              return response.json();
            }
          })
          .then((data) => {
            if (!error) {
              setCustomer(data);
            } else {
              console.log("Error getting customer: " + data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [auth]);

  useEffect(() => {
    setAmount(calcAmount(state.cart.items));
    if (
      state.cart.deliveryDate &&
      state.cart.deliveryDate != null &&
      state.cart.deliveryDate.locker
    ) {
      setLocker(state.cart.deliveryDate.locker);
    } else {
      setLocker(null);
    }
  }, [state]);

  useEffect(() => {
    let lockers = [];
    if (deliveryDates !== null && deliveryDates.length > 0) {
      lockers = deliveryDates
        .map((deliveryDate) => deliveryDate.locker)
        .filter(
          (locker, index, array) =>
            array.findIndex((l) => l.id === locker.id) === index
        );
    }
    setLockers(lockers);
  }, [deliveryDates]);

  const handleDeliveryDateChange = (id) => {
    let deliveryDate = null;
    if (id !== null && id !== "") {
      deliveryDate =
        deliveryDates[
          deliveryDates.map((deliveryDate) => deliveryDate.id).indexOf(id)
        ];
    }
    if (deliveryDate === null) {
      if (locker !== null) {
        setLocker(null);
      }
    } else {
      if (locker == null || deliveryDate.locker.id !== locker.id) {
        setLocker(deliveryDate.locker);
      }
    }
    onDeliveryDateChange(deliveryDate);
  };

  const handleLockerChange = (id) => {
    let locker = null;
    if (id !== null && id !== "") {
      locker = lockers[lockers.map((locker) => locker.id).indexOf(id)];
    }
    if (
      locker !== null &&
      state.cart.deliveryDate &&
      state.cart.deliveryDate !== null &&
      state.cart.deliveryDate.locker.id !== locker.id
    ) {
      onDeliveryDateChange(null);
    }
    setLocker(locker);
  };

  const calcMissingValue = (amount) => {
    return round(minimumOrderValue - amount);
  };

  const checkoutPossible = () => {
    return (
      calcMissingValue(amount) <= 0 &&
      state.cart.deliveryDate &&
      state.cart.deliveryDate !== null &&
      state.cart.deliveryDate.freeCompartments > 0 &&
      remainingMinutes > 0 &&
      auth.loggedIn()
    );
  };

  const getCheckoutNotPossibleText = () => {
    if (calcMissingValue(amount) > 0) {
      return "Erreiche den Mindestbestellwert.";
    }
    if (!state.cart.deliveryDate || state.cart.deliveryDate === null) {
      return "Wähle eine Lieferzeit aus.";
    }
    if (state.cart.deliveryDate.freeCompartments <= 0) {
      return "Wähle eine Lieferzeit zu der noch Fächer frei sind.";
    }
    if (remainingMinutes <= 0) {
      return "Wähle eine Lieferzeit, deren Bestellschluss noch nicht erreicht ist.";
    }
    if (!auth.loggedIn()) {
      return "Du bist noch nicht eingeloggt.";
    }
  };

  const handleCheckout = () => {
    setOpenCheckoutDialog(false);
    onCheckout();
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <DeliveryCartCheckoutItem
            number={1}
            heading="Mindestbestellwert erreichen"
            done={calcMissingValue(amount) <= 0}
          >
            <Typography align="center">
              Wir liefern ab {minimumOrderValue} €
            </Typography>
            {calcMissingValue(amount) > 0 ? (
              <Typography align="center" color="error">
                Dir fehlen noch {formatNumber(calcMissingValue(amount), 2)} €
              </Typography>
            ) : (
              <Typography align="center" color="secondary">
                Mindestbestellwert erreicht
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.continueShoppingButton}
              component={Link}
              to="/shop"
              fullWidth
            >
              Weiter Einkaufen
            </Button>
          </DeliveryCartCheckoutItem>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <DeliveryCartCheckoutItem
            number={2}
            heading="Packstation auswählen"
            done={locker !== null}
          >
            <DeliveryLockerInput
              locker={locker}
              deliveryDates={deliveryDates}
              onChange={handleLockerChange}
            />
          </DeliveryCartCheckoutItem>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <DeliveryCartCheckoutItem
            number={3}
            heading="Lieferzeit auswählen"
            done={
              state.cart.deliveryDate &&
              state.cart.deliveryDate !== null &&
              state.cart.deliveryDate.freeCompartments > 0
            }
          >
            <DeliveryDateInput
              deliveryDate={state.cart.deliveryDate}
              locker={locker}
              deliveryDates={deliveryDates}
              onRemainingMinutesChange={(minutes) =>
                setRemainingMinutes(minutes)
              }
              onChange={handleDeliveryDateChange}
            />
          </DeliveryCartCheckoutItem>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <DeliveryCartCheckoutItem
            number={4}
            heading="Anmelden"
            done={auth.loggedIn()}
          >
            {!auth.loggedIn() ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => auth.login()}
                fullWidth
              >
                Jetzt Anmelden
              </Button>
            ) : (
              <Typography align="center">
                Hallo{" "}
                {auth.user && auth.user.profile
                  ? auth.user.profile.firstname
                  : ""}{" "}
                👋
              </Typography>
            )}
          </DeliveryCartCheckoutItem>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <DeliveryCartCheckoutItem
            heading="Aktionsrabatt einlösen"
            done={state.cart.coupon && state.cart.coupon !== null}
            optional={true}
          >
            <Button
              color="primary"
              variant="outlined"
              startIcon={<CardGiftcard />}
              onClick={() => setOpenCouponDialog(true)}
              disabled={
                !auth.loggedIn() ||
                (state.cart.coupon && state.cart.coupon !== null)
              }
              fullWidth
            >
              Aktionsrabatt hinzufügen
            </Button>
          </DeliveryCartCheckoutItem>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <CheckoutAmount
            items={state.cart.items}
            amount={amount}
            coupon={state.cart.coupon}
            customer={customer}
            removeCoupon={() => onCouponChange(null)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.checkoutButton}>
            <Button
              color="secondary"
              variant="contained"
              disabled={!checkoutPossible()}
              onClick={() => setOpenCheckoutDialog(true)}
              fullWidth
            >
              Einkauf Abschließen
            </Button>
            {!checkoutPossible() && (
              <Typography variant="body2" align="center" color="error">
                {getCheckoutNotPossibleText()}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <DeliveryCouponDialog
        open={openCouponDialog}
        onValidated={onCouponChange}
        handleClose={() => setOpenCouponDialog(false)}
      />
      <SimpleDialog
        open={openCheckoutDialog}
        onClose={() => setOpenCheckoutDialog(false)}
        title="Einkauf wirklich abschließen?"
        content="Mit dem Abschluss machen wir uns an die Arbeit und suchen dir die besten Stücke aus unserem Sortiment raus. Sobald die bestellten Produkte in der von dir ausgewählten Packstation liegen, erhältst du eine weitere Email mit weiteren Details zur Abholung."
        acceptButtonText="Abschließen"
        onAcceptButtonClick={() => handleCheckout()}
        declineButtonText="Abbrechen"
      />
    </Box>
  );
}

export default DeliveryCartCheckoutList;
