import { Box, Button, IconButton, makeStyles, Popover, Typography } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import { useState } from "react";
import { useAuth } from "../../provider/authProvider";

const useStyles = makeStyles(theme => ({
	AccountManagementPopover: {
		width: 150,
		padding: 12,
	},
	logoutButton: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
	}
}))

function AccountManagementButton({className}) {
	const classes = useStyles();
	const auth = useAuth();
	const [anchor, setAnchor] = useState(null);

	const open = Boolean(anchor);

	return (
		<Box>
			<IconButton onClick={(e) => setAnchor(e.currentTarget)}>
				<PersonIcon fontSize="large" />
			</IconButton>
			<Popover
				id="shoppingCartPopover"
				open={open}
				anchorEl={anchor}
				onClose={() => setAnchor(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Box className={classes.AccountManagementPopover}>
					{auth.isInitialized() && auth.loggedIn() ? 
						<Box>
							<Typography align="center" gutterBottom>Hallo {auth.user && auth.user.profile ? auth.user.profile.firstname : ""} 👋</Typography>
							<Button variant="contained" className={classes.logoutButton} onClick={auth.logout} fullWidth>Ausloggen</Button>
						</Box>
					:
						<Box>
							<Button variant="contained" color="primary" onClick={auth.login} fullWidth>Anmelden</Button>
							<Button variant="text" color="secondary" onClick={auth.register} fullWidth>Registrieren</Button>
						</Box> 
					}
				</Box>
			</Popover>
		</Box>
	)
}

export default AccountManagementButton;