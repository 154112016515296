/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Box, CircularProgress, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import { FILTER } from "../../enums/product";
import Logger from "../utilities/logger";
import ProductList from "./productList";
const logger = new Logger({ level: LOG_LEVEL.INFO, target: "products" });
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#eee",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Products(props) {
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AppContext);
    
    const classes = useStyles();

    useEffect(() => {
        refreshProducts();
    },[])

    const refreshProducts = () => {
        setLoading(true);
        var targetUrl = window._env_.RS_HOST.concat("/api/v1/product/all?filter=" + FILTER.CATEGORY);
        fetch(encodeURI(targetUrl), {
            method: 'GET',
        }).then(response => {
            if(response.ok) {
                return response.json();
            } else {
                logger.warn("Response not ok in product request (/api/v1/product/all):", response);
            }
        }).then(data => {
            setLoading(false);
            if(data) {
                dispatch({
                    type: 'setProducts',
                    products: data
                });
            } else {
                logger.warn("No data in product request (/api/v1/product/all)")
            }
        }).catch(function(error) {
            logger.error("Error in product request (/api/v1/product/all):", error);
            setLoading(false);
        });
    }

    return <Box p={4} className={classes.root}>
        <ProductList
            products={state.products}
            filter={FILTER.CATEGORY}
            showStock={false}
            searchBar={true}
            onListItemClick={() => {}}
            offset={props.headerHeight}
            fullscreen={false}
            infiniteScroll={true}
        />
        <Backdrop open={loading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Box>
}

export default Products;