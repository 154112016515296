import { Grid } from "@material-ui/core";
import React from "react";
import { Contact } from "./contact";
import Hero from "./hero";
import { Team } from "./team";
import { Video } from "./video";

const teammember = [
	{name: "Hauke", avatarPath: "/team/hauke.png"},
	{name: "Katha", avatarPath: "/team/katha.jpg"},
	{name: "Marko", avatarPath: "/team/marko.jpeg"},
	{name: "Jürgen", avatarPath: "/team/juergen.jpg"},
	{name: "Julian", avatarPath: "/team/julian.jpeg"},
]

const video = {
	title: "RegioStore Erklärvideo",
	src: "https://www.youtube-nocookie.com/embed/p1igjm-sgzk",
}

const hero = {
	header: "Aus der Region, für die Region",
	text: "RegioStore bietet dir all die guten Produkte aus der Region - bequem, jederzeit verfügbar und an einem Ort",
	action: {
		title: "Jetzt Registrieren",
		link: "/register",
	},
}

export default function Home(props) {
	return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
			<Hero info={hero} />
			<Video 
				id="concept"
				videoSrc={video.src}
				videoTitle={video.title}
			/>
			<Team id="team" team={teammember} />
			<Contact id="contact" />
		</Grid>
	);
}
