/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { Backdrop, Button, ButtonGroup, Card, CardActions, CardContent, CircularProgress, Grid, List, makeStyles, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { useContext, useEffect, useState, useReducer } from "react";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import FeedbackScreen from "../utilities/feedbackScreen";
import Logger from "../utilities/logger";
import { addToShoppingCart, validateShoppingCart, checkShoppingCartState } from "./reservationAction";
import { PRICE_UNIT } from "../../enums/product";

const logger = new Logger({ level: LOG_LEVEL.INFO, target: "shoppingcart" });
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#eee",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    shoppingCartButtonGroup: {
        color: "#efefef", 
        borderColor:"#efefef"
    },
    shoppingCartOrderOverview:{
        //margin: 10,
        borderRadius: 5,
        border: 5,
        position: "relative",
        //backgroundColor: "#3f3c59",
        background: "linear-gradient(45deg, #29273B, #3f3c59, #1D5161)",
        color: "#efefef",
    },
}));

function ShoppingCart() {
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AppContext);
    const [reservationModalVisible, setReservationModalVisible] = useState(false);
    const [reservationCustomerNumber, setReservationCustomerNumber] = useState("");
    const [reservationCustomerMail, setReservationCustomerMail] = useState("");
    const [reservationCustomerPin, setReservationCustomerPin] = useState("");
    const [showReservationError, setShowReservationError] = useState(false);
    const [reservationError, setReservationError] = useState("");
    const [reservationErrorCustomerNumber, setReservationErrorCustomerNumber] = useState(false);
    const [reservationErrorCustomerMail, setReservationErrorCustomerMail] = useState(false);
    const [reservationErrorCustomerPin, setReservationErrorCustomerPin] = useState(false);
    const [checkoutFeedbackText, setCheckoutFeedbackText] = useState("");
    const [checkoutError, setCheckoutError] = useState("");

    const classes = useStyles();

    useEffect(() => {
        var removedProducts = checkShoppingCartState(state.shoppingCartProducts, state.reservationProducts);
        if(removedProducts.length > 0){
            dispatch({
                type: 'setWrongShoppingCart',
                wrongShoppingCart: true,
                removedProducts: removedProducts
            });
            validateShoppingCart(dispatch);
        }
    },[])

    function existsInShoppingCart(product){
        return Array.from(state.shoppingCartProducts.map((item) => item.webProduct.id)).includes(product.id);
    }

    function getShoppingCartItem(product) {
        return state.shoppingCartProducts[state.shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.webProduct.id).indexOf(product.id)];
    }

    function getTotalCount(){
        var total = 0.0;
    
        for (let item of state.shoppingCartProducts) {
            item.webProduct.product.priceUnit === 'WEIGHT' ? 
                total = total + (item.quantity * item.webProduct.product.pricePerUnit * item.weight)
                :
                total = total + (item.quantity * item.webProduct.product.pricePerUnit)
        }
        return total.toFixed(2);
    }

    

    // TODO: Extra component for reservation modal
    function renderReservationModal(){
        return(           
          <Dialog fullWidth={true} fullScreen={window.innerWidth < 800} scroll="body" maxWidth="sm" onClose={() => setReservationModalVisible(false)} aria-labelledby="customized-dialog-title" open={reservationModalVisible}>
                <Backdrop style={{zIndex: 99, color:'#fff'}} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
              <Toolbar className={`${classes.navbar}`}>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Typography variant="h6">
                        Reservierung
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton style={{margin: 0, padding:0}} edge="end" color="inherit" onClick={() => setReservationModalVisible(false)} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              
              <DialogContent dividers style={{position: "relative"}}>
                { showReservationError &&
                <Typography color="error" variant="h6">
                    {reservationError}
                </Typography>
                }
                <TextField
                    required
                    type="text"
                    error={reservationErrorCustomerNumber}
                    label="Kundennummer (6-stellig, steht auf deiner Kundenkarte)"
                    color="secondary"
                    placeholder="Kundennummer (Sechsstellige Nummer auf Ihrer RegioStore-Karte)"
                    helperText={reservationErrorCustomerNumber ? "Bitte überprüfen Sie die eigegebene Kundennummer." : ""}
                    fullWidth
                    value={reservationCustomerNumber}
                    onChange={(e) => {e.persist(); setReservationCustomerNumber(e.target.value)}}
                    onBlur={validateCustomerNumber}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{paddingBottom: 10}}
                    />
                <TextField
                    required
                    type="text"
                    error={reservationErrorCustomerMail}
                    label="Email mit der du beim regioStore registriert bist"
                    color="secondary"
                    placeholder="Email"
                    helperText={reservationErrorCustomerMail ? "Bitte überprüfen Sie die eingegebene Mail-Adresse":""}
                    fullWidth
                    value={reservationCustomerMail}
                    onChange={(e) => {e.persist(); setReservationCustomerMail(e.target.value)}}
                    onBlur={validateEmail}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{paddingBottom: 10}}
                    />
                <TextField
                    required
                    type="password"
                    error={reservationErrorCustomerPin}
                    label="RegioStore-Pin (vierstellig)"
                    color="secondary"
                    placeholder="RegioStore-Pin (vierstellig)"
                    helperText={reservationErrorCustomerPin ? "Bitte überprüfen Sie die eingegebene Pin":""}
                    fullWidth
                    value={reservationCustomerPin}
                    onChange={(e) => {e.persist(); setReservationCustomerPin(e.target.value)}}
                    onBlur={validateCustomerPin}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{paddingBottom: 10}}
                    />
                <ButtonGroup style={{width:"100%", marginTop:20}}>
                    <Button onClick={() => setReservationModalVisible(false)} color="secondary" size="large" style={{width:"100%"}} variant="contained">
                        Abbrechen
                    </Button>
                    <Button onClick={() => validateReservation()} style={{width:"100%"}} color="primary" size="large" variant="contained">
                        Reservieren
                    </Button>
                </ButtonGroup>
              </DialogContent>
          </Dialog>              
        )
    }

    const validateCustomerNumber = (e) => {
        let customerNumber = e.target.value;
        customerNumber = customerNumber.replace(" ", "");
        setReservationErrorCustomerNumber(false);
        const regex = /^([0-9]{6})$/
        if (!customerNumber.match(regex)) {
            setReservationErrorCustomerNumber(true);
        }
        setReservationCustomerNumber(customerNumber);
    }

    const validateCustomerPin = (e) => {
        let customerPin = e.target.value;
        customerPin = customerPin.replace(" ", "");
        setReservationErrorCustomerPin(false);
        const regex = /^([0-9]{4})$/
        if (!customerPin.match(regex)) {
            setReservationErrorCustomerPin(true);
        }
        setReservationCustomerPin(customerPin);
    }

    const validateEmail = (e) => {
        let email = e.target.value;
        email = email.trim();
        setReservationErrorCustomerMail(false);
        if (email !== null && email !== "") {
            const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!email.match(regex)) {
                setReservationErrorCustomerMail(true);
            }
        }
    }

    const invalidInput = () => {
        if (reservationErrorCustomerMail || reservationErrorCustomerNumber || reservationErrorCustomerPin) {
            return true;
        }
        return false;
    }

    const missingInput = () => {
        if (reservationCustomerMail !== undefined && 
            reservationCustomerMail !== null &&
            reservationCustomerMail !== "" &&
            reservationCustomerNumber !== undefined &&
            reservationCustomerNumber !== null && 
            reservationCustomerNumber !== "" &&
            reservationCustomerPin !== undefined &&
            reservationCustomerPin !== null && 
            reservationCustomerPin !== ""
        ) {
            return false;
        }
        return true;
    }

    function validateReservation() {
        if (!loading && !missingInput() && !invalidInput()) {
            setReservationError(false);
            if (state && state.shoppingCartProducts !== undefined &&
                state.shoppingCartProducts !== null &&
                state.shoppingCartProducts.length > 0
            ) {
                sendReservation(reservationCustomerNumber, reservationCustomerMail, reservationCustomerPin);
            } else {
                setReservationError('Dein Warenkorb ist leer.');
            }
        }
    };

    const sendReservation = () => {
        logger.info("perform reservation");
        setLoading(true);
        var targetUrl = window._env_.RS_HOST.concat("/api/v1/regiostore/reservation");
        var error = false;

        fetch(encodeURI(targetUrl), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                customerNumber: reservationCustomerNumber,
                pin: reservationCustomerPin,
                mail: reservationCustomerMail,
                newRegioStoreReservationItemFormModel: state.shoppingCartProducts,
            })
        }).then(response => {
            if(!response.ok) {
                error = true;
            }
            return response.text();
        }).then(text => {
            setReservationModalVisible(false);
            setCheckoutError(error);
            setCheckoutFeedbackText(text);
            setLoading(false);
            if (error) {
                logger.error("Error while sending reservation request (/api/v1/regiostore/reservation):", text);
            } else {
                logger.info("successfully send reservation")
                dispatch({
                    type: 'emptyShoppingCart',
                });
            }

        }).catch(function(error) {
            logger.error("Error while sending reservation request (/api/v1/regiostore/reservation):", error);
            setLoading(false);
        });
    }

    return  <Card className={classes.shoppingCartOrderOverview}>
                <FeedbackScreen 
                    open={checkoutFeedbackText !== ""} 
                    success={!checkoutError} 
                    duration={10} 
                    text={checkoutFeedbackText} 
                    onClose={() => setCheckoutFeedbackText("")}
                />
                <CardContent >
                    <Typography variant="h5" gutterBottom>
                        Reservierungsübersicht
                    </Typography>
                    {state.shoppingCartProducts.length === 0 && 
                        <Typography variant="body1">Dein Warenkorb ist leer</Typography>
                    }
                    <List className={classes.shoppingCartOrderOverviewList} style={{minHeight: window.innerHeight * 0.40, maxHeight: window.innerHeight * 0.70, overflow: 'auto'}}>
                        {state.shoppingCartProducts.length > 0 && state.reservationProducts.map(w => w.webProduct).filter((webProduct) => existsInShoppingCart(webProduct)).map((shoppingCartProduct) => (
                            <div key={shoppingCartProduct.id} style={{borderBottom: "1px solid #ffffff", margin:"8px"}}>
                                <Grid container justifyContent="space-between">  
                                    <Typography variant="body2" align="left"  gutterBottom>
                                    {getShoppingCartItem(shoppingCartProduct).quantity}x {shoppingCartProduct.title} {shoppingCartProduct.product.priceUnit === PRICE_UNIT.PIECE ? "" : "(" + getShoppingCartItem(shoppingCartProduct).weight*1000 + "g)"}
                                    </Typography> 
                                    {
                                    shoppingCartProduct.product.priceUnit === 'WEIGHT' ? 
                                        <Typography variant="body2" align="right">
                                            {((getShoppingCartItem(shoppingCartProduct).quantity * shoppingCartProduct.product.pricePerUnit).toFixed(2) * getShoppingCartItem(shoppingCartProduct).weight).toFixed(2) + '€'}
                                        </Typography>
                                        : 
                                        <Typography variant="body2" align="right">
                                            {((getShoppingCartItem(shoppingCartProduct).quantity * shoppingCartProduct.product.pricePerUnit)).toFixed(2) + '€'}
                                        </Typography>
                                    }
                                    
                                </Grid>
                                <ButtonGroup  style={{paddingBottom: "10px"}} size="small" aria-label="outlined primary button group">
                                    <Button style={{fontSize: "10px"}}  size="small" fontSize="small" className={classes.shoppingCartButtonGroup}  fill="clear" onClick={() => addToShoppingCart(shoppingCartProduct, -1 * getShoppingCartItem(shoppingCartProduct).quantity, shoppingCartProduct.weight, dispatch, state )}>
                                        Entfernen
                                    </Button>
                                    <Button className={classes.shoppingCartButtonGroup} style={{fontSize: "16px"}} size="small" fill="clear" onClick={() => addToShoppingCart(shoppingCartProduct, -1, shoppingCartProduct.weight, dispatch, state)} >
                                        <RemoveIcon size="small" fontSize="inherit" slot="icon-only"/>
                                    </Button>
                                    <Button className={classes.shoppingCartButtonGroup} size="small"  style={{fontSize: "10px"}}  fill="clear">
                                        {getShoppingCartItem(shoppingCartProduct).quantity}
                                    </Button>
                                    <Button className={classes.shoppingCartButtonGroup} style={{fontSize: "16px"}}  fill="solid" onClick={() => {addToShoppingCart(shoppingCartProduct, 1, shoppingCartProduct.weight, dispatch, state);}}>
                                        <AddIcon size="small" fontSize="inherit" slot="icon-only"/>
                                    </Button>
                                </ButtonGroup>
                            </div>        
                        ))}
                    </List>
                    <Grid container justifyContent="space-between" style={{marginTop: 20}}>  
                        <Typography variant="h6" align="left" gutterBottom>
                            Gesamtsumme
                        </Typography>         
                        <Typography variant="body1" align="right">{getTotalCount()} €</Typography>
                    </Grid>
                    <Typography variant="body2" align="left" gutterBottom>        
                        (inkl. Mehrwertsteuer)
                    </Typography> 
                    <Typography variant="body2" align="left" gutterBottom>        
                        Die Gewichtsangaben sind nur eine Schätzung. Der Preis wird sich abhängig vom genauen Gewicht der angefragten Produkte noch ändern.
                    </Typography> 
                    <Typography variant="body2" align="left" gutterBottom>        
                        Abgerechnet und abgebucht wird der Betrag erst nach dem Erhalt der Produkte.
                    </Typography> 
                </CardContent>
                <CardActions>
                    <Button 
                        style={{width:"100%", backgroundColor: "#fc9800", color: "#efefef"}} 
                        size="large" 
                        variant="contained" 
                        onClick={() => {
                            var removedProducts = checkShoppingCartState(state.shoppingCartProducts, state.reservationProducts);
                            if(removedProducts.length > 0){
                                dispatch({
                                    type: 'setWrongShoppingCart',
                                    wrongShoppingCart: true,
                                    removedProducts: removedProducts,
                                });
                                validateShoppingCart(dispatch);
                            }else{
                                setReservationModalVisible(true);
                            }
                        }}
                        disabled={state.shoppingCartProducts.length <= 0}
                    >
                        Jetzt Reservieren
                    </Button>
                </CardActions>
                {renderReservationModal()}
            </Card>
}

export default ShoppingCart;