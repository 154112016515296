import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { CardGiftcard, Delete } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { LOG_LEVEL } from "../../enums/log";
import { theme } from "../../theme";
import { calcAmount } from "../products/productUtils";
import Divider from "../utilities/divider";
import Logger from "../utilities/logger";
import { formatNumber } from "../utilities/numberUtils";
import CheckoutList from "./checkoutList";

const useStyles = makeStyles(theme => ({
    root: props => ({
        width: "100%",
        height: `calc(100vh - 3rem - ${props.offset}rem)`,
        paddingBlock: 16,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
    }),
    heading: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        width: 100,
        marginInline: 10,
    },
    checkoutControlPanel: {
        paddingInline: 16,
        paddingBlock: 8,
    },
    pinContainer: {
        display: "flex",
        flexDirection: "column",
    },
    pinTextField: {
        marginTop: theme.spacing(4),
        marginInline: "auto",
    },
    bold: {
        fontWeight: 600
    },
    couponDeleteButton: {
        marginInlineStart: 8,
        minWidth: 32,
    }
}));

function Checkout(props) {
    const classes = useStyles(props);
    const [totalAmount, setTotalAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
    const [pin, setPin] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContentText, setDialogContentText] = useState("");
    const [dialogAcceptButtonText, setDialogAcceptButtonText] = useState("Ja");
    const [dialogCancelButtonText, setDialogCancelButtonText] = useState("Nein");
    
    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "checkout" });

    useEffect(() => {
        const calculatedAmount = calcAmount(props.checkoutItems);
        const calculatedDiscountAmount = props.customer.discount > 0 ? calculatedAmount * props.customer.discount / 100 : 0;
        const calculatedCouponDiscountAmount = props.coupon != null ? (calculatedAmount - calculatedDiscountAmount) * props.coupon.discount / 100 : 0;
        setDiscountAmount(calculatedDiscountAmount);
        setCouponDiscountAmount(calculatedCouponDiscountAmount);
        setTotalAmount(calculatedAmount - calculatedDiscountAmount - calculatedCouponDiscountAmount);
    },[
        props.customer,
        props.checkoutItems,
        props.coupon,
    ])

    const handleClose = () => {
        logger.debug("handle close");
        setOpenDialog(false);
        setCheckout(false);
        setDialogTitle("");
        setDialogContentText("");
        setDialogCancelButtonText("Nein");
        setDialogAcceptButtonText("Ja");
    }

    const handleAccept = () => {
        logger.debug("handle accept");
        handleClose();
        if (checkout) {
            logger.info("user started checkout");
            props.onCheckout(pin);
        } else {
            logger.info("user canceled purchase");
            props.onCancel();
        }
        setPin("");
    }

    const openCancelDialog = () => {
        logger.debug("open cancel dialog");
        setDialogTitle("Einkauf abbrechen");
        setDialogContentText("Möchtest du deinen Einkauf wirklich abbrechen?");
        setOpenDialog(true);
        setCheckout(false);
        setDialogCancelButtonText("Nein");
        setDialogAcceptButtonText("Ja");
    }

    const openCheckoutDialog = () => {
        if (!props.preventCheckout) {
            logger.debug("open checkout dialog");
            setDialogTitle("Einkauf abschließen");
            setDialogContentText("Dein Einkauf wird kostenpflichtig abgeschlossen. Nach dem Abschließen buchen wir den Gesamtbetrag von deinem Konto ab. Die Rechnung erhältst du per Email.");
            setOpenDialog(true);
            setCheckout(true);
            setDialogCancelButtonText("Abbrechen");
            setDialogAcceptButtonText("Ok");
        }
    }

    const handleKeypress = e => {
        logger.debug("handle keypress");
        //it triggers by pressing the enter key
        if (checkout && e.key === "Enter" && pin.length >= 4) {
            logger.debug("key is Enter");
            handleAccept();    
        }
    };

    
    return (
        <Box boxShadow={3} borderRadius={16} className={classes.root}>
            <Box>
                <Typography gutterBottom variant="h6" color="textPrimary" align="center">
                    Hallo {props.customer.firstname} 👋
                </Typography>
            </Box>
            <Box className={classes.heading}>
                <Typography variant="h4" color="textPrimary" align="center">
                    Dein
                </Typography>
                <img src="/Logo.svg" alt="Logo" className={classes.logo} />
                <Typography variant="h4" color="textPrimary" align="center">
                    Einkauf
                </Typography>
            </Box>
            
            <Divider align="center" marginBottom="1rem" />
            <CheckoutList checkoutItems={props.checkoutItems} onChangeQuantity={props.onChangeQuantity} />
            <Box className={classes.checkoutControlPanel}>
                <Grid container spacing={theme.spacing(1)}>
                    {discountAmount > 0 ? <Grid item xs={10}>
                        <Typography variant="body1">
                            {"Rabatt (" + formatNumber(props.customer.discount, 2) + " %)"}
                        </Typography>
                    </Grid> : null }
                    {discountAmount > 0 ? <Grid item xs={2}>
                        <Typography variant="body1" align="right">
                            {"- " + formatNumber(discountAmount, 2) + " €"}
                        </Typography>
                    </Grid> : null }
                    {props.coupon != null && couponDiscountAmount > 0 ? <Grid item xs={10}>
                        <Typography variant="body1" component="span">
                            {"Aktionsrabatt (" + props.coupon.code + " - " + formatNumber(props.coupon.discount, 2) + " %)"}
                        </Typography>
                        <Button variant="outlined" color="primary" size="small" fill="clear" className={classes.couponDeleteButton} onClick={props.removeCoupon}>
                            <Delete slot="icon-only" fontSize="small" />
                        </Button>
                    </Grid> : null }
                    {props.coupon != null && couponDiscountAmount > 0 ? <Grid item xs={2}>
                        <Typography variant="body1" align="right">
                            {"- " + formatNumber(couponDiscountAmount, 2) + " €"}
                        </Typography>
                    </Grid> : null }
                    <Grid item xs={10}>
                        <Typography variant="body1" className={classes.bold}>
                            Gesamtbetrag
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" align="right" className={classes.bold}>
                            {formatNumber(totalAmount, 2) + " €"}
                        </Typography>
                    </Grid>
                    {props.coupon == null ? <Grid item xs={12}>
                        <Button variant="outlined" color="primary" fullWidth onClick={() => props.openCouponDialog()} startIcon={<CardGiftcard />}>
                            Aktionsrabatt hinzufügen
                        </Button>
                    </Grid> : null}
                    <Grid item xs={6}>
                        <Button variant="contained" color="secondary" fullWidth disabled={props.checkoutItems.length <= 0} onClick={() => openCheckoutDialog()}>
                            Bezahlen
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={() => openCancelDialog()}>
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="dialog-title">
                <DialogTitle id="dialog-title" onClose={handleClose}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom variant="body1">
                        {dialogContentText}
                    </Typography>
                    {checkout ? <Box className={classes.pinContainer}>
                        <Typography variant="body1">Bitte gebe zur Bestätigung hier einmal deinen Pin ein:</Typography>
                        <TextField 
                            id="pinTextField"
                            placeholder="PIN"
                            label="PIN"
                            variant="outlined"
                            value={pin}
                            onChange={(e) => {e.persist(); setPin(e.target.value)}}
                            type="password"
                            onKeyPress={handleKeypress}
                            autoFocus
                            className={classes.pinTextField}
                            inputProps={{pattern: "[0-9]*", inputMode: "numeric"}}
                        />
                    </Box>: null}
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                    >
                        {dialogCancelButtonText}
                    </Button>
                    <Button 
                        variant="contained"
                        color="secondary"
                        onClick={handleAccept}
                        disabled={checkout && pin.length < 4} 
                    >
                        {dialogAcceptButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Checkout;