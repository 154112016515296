import { Box, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { Heading } from "../utilities/heading";

const useStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    content: {
        width: "100%",
        maxWidth: 1152,
        padding: 30,
        marginTop: 60,
    },
    videoWrapper: {
        position: "relative",
        paddingBottom: "56.25%",
        height: 0,
    },
});

export function Video(props) {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box id={props.id} border={0} className={classes.content}>
                <Heading title="Und so funktioniert's" />
                <div className={classes.videoWrapper}>
                    <iframe 
                        className={classes.video}
                        style={{
                            position: "absolute",
                            border: "5px dotted",
                            borderColor: theme.palette.primary.main,
                            height: "100%",
                            width: "100%",
                        }}
                        src={props.videoSrc}
                        title={props.videoTitle}
                        allowFullScreen 
                    />
                </div>
            </Box>
        </Box>
    );
}