import { Typography } from "@material-ui/core"
import Divider from "./divider"

export function Heading(props) {
    return (
        <div>
            <Typography variant="h4" color="textPrimary">
                {props.title}
            </Typography>
            <Divider align="left" marginBottom="2rem" />
        </div>
    )
}