import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Divider from "../utilities/divider";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		width: "100%",
		height: "60vh",
		backgroundImage: "url(/heroBackground.svg) !important",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		width: "100%",
		maxWidth: 1152,
		padding: 30,
		margin: "auto",
	},
	header: {
		marginBlockEnd: 16,
	},
	text: {
		marginBlockEnd: 16,
	},
	callToAction: {
		display: "flex",
		justifyContent: "center",
	},
}));

export default function Hero({info}) {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.content}>
				<Box className={classes.header}>
					<Typography variant="h4" align="center" gutterBottom>
						{info.header}
					</Typography>
					<Divider align="center" />
				</Box>
				<Box className={classes.text}>
					<Typography align="center">
						{info.text}
					</Typography>
				</Box>
				<Box className={classes.callToAction}>
					<Button
						variant="contained"
						color="primary"
						component={Link}
						to={info.action.link}
					>
						{info.action.title}
					</Button>
				</Box>
			</Box>
		</Box>
	);
}