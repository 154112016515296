import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { HashLink } from "react-router-hash-link";
 
const useStyles = makeStyles({
    root: {
        padding: "1.5rem 0rem",
        width: "100%",
        flexShrink: 0,
        textAlign: "center",
        display: "block",
        backgroundColor: "#3f3c59",
    },
    socialMedia: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        fontSize: "2em",
        color: "#fc9800",
    },
    links: {
        marginTop: "1.5rem",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: 0,
    },
    link: {
        display: "flex",
        justifyContent: "space-around",
        flexGrow: 0,
        flexShrink: 1,
        lineHeight: 32,
        padding: "0px 24px",
        color: "#f2f2f2",
    },
    copyright: {
        marginTop: "2rem",
        width: "100%",
        fontSize: "small",
        color: "#f2f2f2",
    }
});

export default function Footer(props) {
    const classes = useStyles();
    return (
        <footer className={classes.root}>
            <div className={classes.socialMedia}>
                <span>
                    {props.links && props.links.socialMedia && !!props.links.socialMedia.length && props.links.socialMedia.map((link) => (
                        <a key={link.name.toLowerCase()} href={link.path}> <FontAwesomeIcon icon={["fab", link.name.toLowerCase()]} /> </a>
                    ))}
                </span>
            </div>
            <div>
                <ul className={classes.links}>
                    {props.links && props.links.intern && !!props.links.intern.length && props.links.intern.map((link) => (
                        <HashLink key={link.name.toLowerCase()} to={link.path} className={classes.link}>
                            <Typography color="inherit">{link.name}</Typography>
                        </HashLink>
                    ))}
                </ul>
            </div>
            <div className={classes.copyright}>
                <Typography variant="inherit" color="inherit">© 2023 <a href="https://regioshopper.com">RegioShopper</a></Typography>
            </div>
        </footer>
    );
}