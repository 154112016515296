import { Box, Breadcrumbs, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { theme } from "../../theme";
import ShopQuantityControl from "../delivery/shopQuantityControl";
import DataArea from "../utilities/dataArea";
import { formatNumber } from "../utilities/numberUtils";
import ProductImage from "./productImage";
import ProductLabel from "./productLabel";
import { getLabelList } from "./productUtils";

const useStyles = makeStyles(theme => ({
	brand: {
		textTransform: "capitalize",
	},
	close: {
		position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
	},
	labels: {
		display: "flex"
	},
	tags: {
		display: "flex",
		flexWrap: "wrap",
		'& > *': {
      margin: theme.spacing(1),
    },
		marginBlockStart: 8,
		marginBlockEnd: 20,
	},
	producerInfo: {
		whiteSpace: "pre-line",
		marginBlockEnd: 16,
	}
}));

function ProductDetailDialog({
	open, 
	product, 
	onQuantityChange, 
	onClose, 
	showRegionalLabels
}) {
	const { t } = useTranslation();
	const mobile = useMediaQuery('(max-width:600px)')
	const classes = useStyles();
	const [info, setInfo] = useState(null);
	const [detail, setDetail] = useState(null);

	useEffect(() => {
		if (product) {
			fetch(encodeURI(window._env_.RS_HOST.concat("/api/v1/product/" + product.productId)), {
				method: 'GET',
			}).then(response => {
				if(response.ok) {
					return response.json();
				}
			}).then(data => {
				if(data) {
					setInfo(data);
					if (data.productDetails) {
						setDetail(JSON.parse(data.productDetails.details))
					} else {
						setDetail(null);
					}
				}
			}).catch(error => {
				console.log(error);
			});
		}
	}, [product]);

	const getProductTitle = () => {
		if (product) {
			return product.title;
		}
		return "";
	}

	const getProducerInfo = () => {
		if (detail) {
			return detail.piv_abwiv_name + "\n" + detail.piv_abwiv_adr_strasse + "\n" + detail.piv_abwiv_adr_plz + " " + detail.piv_abwiv_adr_ort;
		} else if (info) {
			return info.producer + "\n" + info.origin;
		}
		return "";
	}

	const getNutritionInfo = () => {
		if (detail) {
			let referenceSize = null;
			if (detail.nwae_bezugsgroesse_id) {
				referenceSize = "Durchschnittlich pro "
				if (detail.nwae_bezugsgroesse_id === 1) {
					referenceSize += "100 g";
				} else if (detail.nwae_bezugsgroesse_id === 2) {
					referenceSize += "100 ml";
				} else if (detail.nwae_bezugsgroesse_id === 3) {
					referenceSize += "100 g Zubereitung";
				} else if (detail.nwae_bezugsgroesse_id === 4) {
					referenceSize += "100 ml Zubereitung";
				}
			}
			let energy = [];
			if (detail.nwae_energie_brennwert_kj) {
				energy.push(detail.nwae_energie_brennwert_kj + " kJ");
			}
			if (detail.nwae_energie_brennwert_kcal) {
				energy.push(detail.nwae_energie_brennwert_kcal + " kcal");
			}
			return (
				<Grid container>
					{referenceSize && 
						<Grid key="referenceSize" item xs={12}>
							<Typography>{referenceSize}</Typography>
						</Grid>
					}
					{energy && energy.length > 0 && [
						<Grid key="energyLabel" item xs={6}>
							<Typography>Energie</Typography>
						</Grid>,
						<Grid key="energyValue" item xs={6}>
							<Typography align="right">{energy.join(" / ")}</Typography>
						</Grid>
					]}
					{detail.nwae_fett && [
						<Grid key="fatLabel" item xs={9}>
							<Typography>Fett</Typography>
						</Grid>,
						<Grid key="fatValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_fett.value ? detail.nwae_fett.value : detail.nwae_fett, 2)} g</Typography>
						</Grid>
					]}
					{detail.nwae_gesaettigte_fettsaeuren && [
						<Grid key="saturatedFatLabel" item xs={9}>
							<Typography>davon gesättigte Fettsäuren</Typography>
						</Grid>,
						<Grid kay="saturatedFatValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_gesaettigte_fettsaeuren.value ? detail.nwae_gesaettigte_fettsaeuren.value : detail.nwae_gesaettigte_fettsaeuren, 2)} g</Typography>
						</Grid>
					]}
					{detail.nwae_kohlenhydrate && [
						<Grid key="carbohydratesLabel" item xs={9}>
							<Typography>Kohlenhydrate</Typography>
						</Grid>,
						<Grid key="carbohydratesValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_kohlenhydrate.value ? detail.nwae_kohlenhydrate.value : detail.nwae_kohlenhydrate, 2)} g</Typography>
						</Grid>
					]}
					{detail.nwae_zucker && [
						<Grid key="sugarLabel" item xs={9}>
							<Typography>davon Zucker</Typography>
						</Grid>,
						<Grid key="sugarValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_zucker.value ? detail.nwae_zucker.value : detail.nwae_zucker, 2)} g</Typography>
						</Grid>
					]}
					{detail.nwae_ballaststoffe && [
						<Grid key="fibreLabel" item xs={9}>
							<Typography>Ballaststoffe</Typography>
						</Grid>,
						<Grid key="fibreValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_ballaststoffe.value ? detail.nwae_ballaststoffe.value : detail.nwae_ballaststoffe, 2)} g</Typography>
						</Grid>
					]}
					{detail.nwae_eiweiss && [
						<Grid key="proteinLabel" item xs={9}>
							<Typography>Eiweiß</Typography>
						</Grid>,
						<Grid key="proteinValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_eiweiss.value ? detail.nwae_eiweiss.value : detail.nwae_eiweiss, 2)} g</Typography>
						</Grid>
					]}
					{detail.nwae_salz && [
						<Grid key="saltLabel" item xs={9}>
							<Typography>Salz</Typography>
						</Grid>,
						<Grid key="saltValue" item xs={3}>
							<Typography align="right">{formatNumber(detail.nwae_salz.value ? detail.nwae_salz.value : detail.nwae_salz, 2)} g</Typography>
						</Grid>
					]}
				</Grid>
			);
		}
		return null;
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			fullScreen={mobile}
			maxWidth="lg"
			aria-labelledby="product-detail-dialog-title"
		>
			<DialogTitle id="product-detail-dialog-title" onClose={onClose} disableTypography>
				<Typography variant="h5">
					{getProductTitle()}
				</Typography>
				<IconButton aria-label="close" className={classes.close} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={theme.spacing(4)}>
					<Grid item container spacing={theme.spacing(2)} xs={12} sm={4} md={4} lg={4} xl={4}>
						<Grid item xs={12}>
							<ProductImage src={product && product.imagePath !== null ? window._env_.RS_HOST.concat(product.imagePath) : null} />
						</Grid>
						<Grid item xs={12}>
							<ProductLabel 
								className={classes.labels}
								labels={getLabelList(product, showRegionalLabels)}
								inspection={info && info.organicInspection}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="column" spacing={theme.spacing(2)} xs={12} sm={8} md={8} lg={8} xl={8}>
						<Box>
							<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
								<Breadcrumbs key="categoryGroups" separator="/">
									{info && info.categoryGroups && info.categoryGroups.map(categoryGroup => (
										<Link key={"categoryGroup"+categoryGroup} color="inherit" to={"/shop?categoryGroup=" + categoryGroup}>{t("categoryGroup.".concat(categoryGroup),categoryGroup)}</Link>
									))}
								</Breadcrumbs>
								<Breadcrumbs key="categories" separator="/">
									{info && info.categories && info.categories.map(category => (
										<Link key={"category"+category.name} color="inherit" to={"/shop?categoryGroup=" + category.group + "&category=" + category.name}>{t("category.".concat(category.name),category.name)}</Link>
									))}
								</Breadcrumbs>
							</Breadcrumbs>
						</Box>
						<Box className={classes.tags}>
							{info && info.regionalType &&
								<Chip 
									key={"tagREGIONAL"} 
									component={Link}
									variant="outlined" 
									size="small" 
									color="secondary" 
									label={t("tag.REGIONAL","REGIONAL")} 
									to={"/shop?tag=REGIONAL"}
									clickable
								/>
							}
							{info && info.tags && info.tags.map(tag => (
								<Chip 
									key={"tag"+tag} 
									component={Link}
									variant="outlined" 
									size="small" 
									color="secondary" 
									label={t("tag.".concat(tag),tag)} 
									to={"/shop?tag=" + tag}
									clickable
								/>
							))}
						</Box>
						<Box>
							<Typography variant="body2" className={classes.brand} gutterBottom>
								{info && info.brand && info.brand.toLowerCase()}
							</Typography>
						</Box>
						<Box>
							<Typography key="producerLabel" variant="caption">
								Hersteller:
							</Typography>
							<Typography key="producerValue" className={classes.producerInfo} variant="body2">
								{getProducerInfo()}
							</Typography>
						</Box>
						<Box>
							<Typography key="description" gutterBottom>
								{info && info.description}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DataArea color="secondary" hidden={!info || !info.ingredients} heading={"Zutaten"}>
							<Typography>
								{info && info.ingredients && info.ingredients}
							</Typography>
						</DataArea>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DataArea color="secondary" hidden={!detail} heading={"Nährwerte"}>
							{detail && getNutritionInfo()}
						</DataArea>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={onClose}>
					Schließen
				</Button>
				{onQuantityChange && <ShopQuantityControl product={product} onQuantityChange={onQuantityChange} />}
			</DialogActions>
		</Dialog>
	);
}

export default ProductDetailDialog;