import {makeStyles, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";

const useStyles = makeStyles(theme => ({
    counter: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
        margin: "10px auto",
        padding: "5px",
    },
    time: {
        margin: "0 3vw 0",
    },
    timeValue: {
        padding: "1px",
        fontSize: "clamp(12px, 4.2vw , 45px)",
        color: "#fff",
        lineHeight: 1,
    },
    timeLabel: {
        textAlign: "center",
        color: "#57B793",
        fontSize: "clamp(10px, 3vw , 15px)",
    }
}));

function Countdown({startDate, counter}) {
    const classes = useStyles();
    const { state, dispatch } = useContext(AppContext);    

 
    const getCountdown = () => {
        var convertedDays = Math.floor(counter / (24 * 3600));
        var convertedHours = Math.floor((counter - Math.floor(counter / (24 * 3600)) * 24 * 3600) / 3600);
        var convertedMins = Math.floor((counter - (Math.floor(counter / (24 * 3600)) * 24 * 3600 + Math.floor((counter - Math.floor(counter / (24 * 3600)) * 24 * 3600) / 3600) * 3600)) / 60);
        var convertedSeconds = Math.floor(counter - (Math.floor(counter / (24 * 3600)) * 24 * 3600 + Math.floor((counter - Math.floor(counter / (24 * 3600)) * 24 * 3600) / 3600) * 3600 + Math.floor((counter - (Math.floor(counter / (24 * 3600)) * 24 * 3600 + Math.floor((counter - Math.floor(counter / (24 * 3600)) * 24 * 3600) / 3600) * 3600)) / 60) * 60));
        return <div>
            <Typography style={{fontSize: "clamp(12px, 3vw , 25px)", color: "#fff"}} component="div" variant="h6">
            Start am: {startDate.toLocaleDateString("de-DE")} um {startDate.toLocaleTimeString("de-DE")} Uhr
        </Typography>
        <div className={classes.counter}>
            <div className={classes.time}>
              <div className={classes.timeValue}>{convertedDays}</div>
              <div className={classes.timeLabel}>{convertedDays === 1 ? "Tag" : "Tage"}</div>
            </div>
            <div className={classes.time}>
                <div className={classes.timeValue}>{convertedHours}</div>
                <div className={classes.timeLabel}>{convertedHours === 1 ? "Stunde" : "Stunden"}</div>
            </div>
            <div className={classes.time}>
              <div className={classes.timeValue}>{convertedMins}</div>
              <div className={classes.timeLabel}>{convertedMins === 1 ? "Minute" : "Minuten"}</div>
            </div>
            <div className={classes.time}>
              <div className={classes.timeValue}>{convertedSeconds}</div>
              <div className={classes.timeLabel}>Sekunden</div>
            </div>
        </div>
      </div>
    }
   
    return getCountdown();
}

export default Countdown;