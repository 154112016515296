import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Snackbar, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from 'react';
import { LOG_LEVEL } from "../../enums/log";
import { useAuth } from "../../provider/authProvider";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
	couponTextField: {
		marginTop: theme.spacing(4),
		marginInline: "auto",
	},
	alert: {
		paddingBlock: 15,
		paddingInline: 25,
	},
}));

function DeliveryCouponDialog({open, handleClose, onValidated}) {
	const auth = useAuth();
	const classes = useStyles();
	const [code, setCode] = useState("");
	const [errorText, setErrorText] = useState("");

	const logger = new Logger({ level: LOG_LEVEL.INFO, target: "couponDialog" });

	const validateCoupon = () => {
		let error = true;
		auth.request(encodeURI(window._env_.RS_HOST.concat("/api/v2/regiostore/customer/coupon?code=" + code)), {
			method: 'GET',
		}).then(response => {
			if(response.ok) {
				error = false;
				return response.json();
			} else {
				error = true;
				return response.text();
			}
		}).then(data => {
			if (!error) {
				handleClose();
				onValidated(data);
				setCode("");
			} else {
				logger.warn("Error while validating coupon code", code, ": ", data);
				setErrorText("Fehler beim Validieren des Coupon Codes: " + data);
			}
		}).catch(function(error) {
			logger.error("Error in validate request for coupon code", code, "(/api/v2/regiostore/customer/coupon):", error);
			setErrorText("Fehler beim Validieren des Coupon Codes: " + error);
		});
	};

	const handleKeypress = e => {
		logger.debug("handle keypress");
		//it triggers by pressing the enter key
		if (e.key === "Enter" && code.length >= 7) {
			logger.debug("key is Enter");
			validateCoupon();    
		}
	};

	const handleScanErrorClose = () => {
		logger.debug("handle scan error close");
		setErrorText("");
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			aria-labelledby="dialog-title"
		>
			<DialogTitle id="dialog-title" onClose={handleClose}>
				Aktionsrabatt
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1" gutterBottom>
					Gib hier deinen Coupon Code ein: 
				</Typography>
				<TextField 
					id="couponTextField"
					placeholder="Coupon Code"
					label="Coupon Code"
					variant="outlined"
					value={code}
					onChange={(e) => {e.persist(); setCode(e.target.value)}}
					type="text"
					onKeyPress={handleKeypress}
					autoFocus
					className={classes.couponTextField}
					inputProps={{pattern: "[0-9A-Z]*"}}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={handleClose}>
					Schließen
				</Button>
				<Button variant="contained" color="secondary" disabled={code < 7} onClick={validateCoupon}>
					Hinzufügen
				</Button>
			</DialogActions>
			<Snackbar
				id="validateCouponCodeErrorSnackbar"
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={errorText !== ""} 
				autoHideDuration={10000} 
				onClose={handleScanErrorClose}
			>
				<Alert 
					id="validateCouponCodeErrorAlert"
					variant="filled"
					className={classes.alert}
					onClose={handleScanErrorClose} 
					severity="error"
				>
					{errorText}
				</Alert>
			</Snackbar>
		</Dialog>
	);
}

export default DeliveryCouponDialog;