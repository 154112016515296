import { Avatar, Container, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    text: {
        paddingBottom: 25,
    },
    avatar: {
        width: 250,
        height: 250,
        border: 2,
        borderColor: "#fc9800",
        marginBottom: 2,
        marginInline: "auto",
    }
}));

export default function Teammember(props) {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Container fixed className={classes.root}>
            <Avatar 
                alt={props.name} 
                src={props.avatarPath} 
                className={classes.avatar} 
                style={{
                    border: '3px solid', 
                    borderColor: theme.palette.primary.main,
                }} 
            />
            <Typography variant="h6" color="textPrimary" align="center" className={classes.text}>
                {props.name}
            </Typography>
        </Container>
    );
}