import { Box, Button, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStorage } from "../../useStorage";

function CookieBanner() {
  const [useCookies, setUseCookies] = useStorage(window.localStorage, 'useCookies', false);
  const [displayCookieBanner, setDisplayCookieBanner] = useStorage(window.sessionStorage, 'displayCookieBanner', true);

  const handleResponse = (allow) => {
    setUseCookies(allow);
    setDisplayCookieBanner(false);
  }

	return (
		<Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={!useCookies && displayCookieBanner}
      onClose={() => {}} 
      message="Wir nutzen Cookies, um dir die beste Einkaufserlebnis zu bieten. Bitte teile uns mit, ob du damit einverstanden bist."
      action={
        <Box>
          <Button variant="text" color="primary" onClick={() => handleResponse(true)}>Erlauben</Button>
          <Button variant="text" color="primary" onClick={() => handleResponse(false)}>Ablehnen</Button>
          <Button variant="text" color="primary" component={Link} to="/shop?categoryGroup=SNACKS&category=SWEETS">Bring mich zu den Cookies</Button>
        </Box>
      }
    />
	)
}

export default CookieBanner;