import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { theme } from "../../theme";
import { calcCouponDiscountAmount, calcDiscountAmount } from "../products/productUtils";
import { formatNumber } from "../utilities/numberUtils";

const useStyles = makeStyles(theme => ({
	checkoutControlPanel: {
		paddingInline: 16,
		paddingBlock: 8,
	},
	bold: {
		fontWeight: 600
	},
	couponDeleteButton: {
		marginInlineStart: 8,
		minWidth: 32,
	}
}));

function CheckoutAmount({customer, amount, coupon, removeCoupon}) {
	const classes = useStyles();
	const [totalAmount, setTotalAmount] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);

	useEffect(() => {
		const calculatedDiscountAmount = calcDiscountAmount(amount, customer ? customer.discount : 0);
		const calculatedCouponDiscountAmount = calcCouponDiscountAmount((amount - calculatedDiscountAmount), coupon)
		setDiscountAmount(calculatedDiscountAmount);
		setCouponDiscountAmount(calculatedCouponDiscountAmount);
		setTotalAmount(amount - calculatedDiscountAmount - calculatedCouponDiscountAmount);
	},[
			customer,
			amount,
			coupon,
	])

	return (
		<Box className={classes.checkoutControlPanel}>
			<Grid container spacing={theme.spacing(1)}>
				{discountAmount > 0 ? <Grid item xs={9}>
					<Typography variant="body1">
						{"Rabatt (" + formatNumber(customer.discount, 2) + " %)"}
					</Typography>
				</Grid> : null }
				{discountAmount > 0 ? <Grid item xs={3}>
					<Typography variant="body1" align="right">
						{"- " + formatNumber(discountAmount, 2) + " €"}
					</Typography>
				</Grid> : null }
				{coupon != null && couponDiscountAmount > 0 ? <Grid item xs={9}>
					<Typography variant="body1" component="span">
						{"Aktionsrabatt (" + coupon.code + " - " + formatNumber(coupon.discount, 2) + " %)"}
					</Typography>
					<Button variant="outlined" color="primary" size="small" fill="clear" className={classes.couponDeleteButton} onClick={removeCoupon}>
						<Delete slot="icon-only" fontSize="small" />
					</Button>
				</Grid> : null }
				{coupon != null && couponDiscountAmount > 0 ? <Grid item xs={3}>
					<Typography variant="body1" align="right">
						{"- " + formatNumber(couponDiscountAmount, 2) + " €"}
					</Typography>
				</Grid> : null }
				<Grid item xs={9}>
					<Typography variant="body1" className={classes.bold}>
						Gesamtbetrag
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="body1" align="right" className={classes.bold}>
						{formatNumber(totalAmount, 2) + " €"}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}

export default CheckoutAmount;